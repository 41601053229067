import React, {useState, useEffect, useCallback} from 'react';
import { debounce } from '@brightsource/brightsource-ui-lib';
import companyStore from '../../../stores/company.store';
import searchStore from '../../../stores/search.store';
import userStore from '../../../stores/user.store';
import ComplexInputComponent from '../../components/forms/complexInput/ComplexInput.component';
import ComplexMultiSelectComponent2 from '../../components/forms/complexMultiSelect2/ComplexMultiSelect2.component';
import BaseSearchWithNetwork from '../../components/forms/BaseSearchWithNetwork.Component';
import ModalComponent from '../../components/modal/Modal.component';
import { CloseIcon2 } from '../../components/svg';
import './createEditUser.scss';
import data from '../../../constants/data';
import utilsService from '../../../services/utils.service';

const CreateEditUser = props => {
    const {user, profile} = props;

    const getPermissionByElId = elId => {
        switch(elId) {
            case 0:
                return {elId: 0, value: "disabled"};
            case 1:
                return {elId: 1, value: "Not Set"};
            case 2:
                return {elId: 2, value: "Recruiter"};
            case 4:
                return {elId: 4, value: "Hiring Manager"};
            case 5:
                return {elId: 5, value: "Admin"};
            default:
                return {elId: 1, value: "Not Set"};
        }
    }
    const getApplicationAccessByElId = elId => {
        switch(elId) {         
            case 0:
                return {elId: 0, value: "All"};
            case 1:
                return {elId: 0, value: "Disabled"};
            case 2:
                return {elId: 2, value: "Employer"};
            case 3:
                return {elId: 3, value: "Recruiter"};
            default:
                return {elId: 0, value: "Not Set"};
        }
    }
    const getGroupByElId = elId => {
        switch(elId) {
            case 0:
                return {elId: 0, value: "All"};
            case 1:
                return {elId: 0, value: "Disabled"};
            case 2:
                return {elId: 2, value: "Employer"};
            case 3:
                return {elId: 3, value: "Recruiter"};
            default:
                break;
        }
    }

    const initProfiles = () => {
        switch(props.screenType) {
            case 'create':
                return [{elId: "1", value: "--- Create New Profile ---"}]
            case 'edit':
                return []
            case 'invite':
                return []
            default:
                return true;
        }  
    }

    let companyData = null;
    if(user && user.companyData) {
        if(Array.isArray(user.companyData)) {
            companyData = user.companyData[0];
        }
        else {
            companyData = user.companyData;
        }
    }
    const [selectedCompany, setSelectedCompany] = useState(companyData && companyData.name ? [{_id: companyData?._id, value: companyData?.name}] : []);

    // const _initProfiles = [{elId: "1", value: "--- Create New Profile ---"}];
    const [profiles, setProfiles] = useState(initProfiles());
    const [selectedProfile, setSelectedProfile] = useState([]);
    const [selectedFirstName, setSelectedFirstName] = useState('');
    const [selectedLastName, setSelectedLastName] = useState('');
    const [selectedEmail, setSelectedEmail] = useState('');
    const [selectedBusinessEmail, setSelectedBusinessEmail] = useState('');
    const [selectedPermissionType, setSelectedPermissionType] = useState(user && [getPermissionByElId(user.permission)]);
    const [selectedUserDetails, setSelectedUserDetails] = useState([]);
    const [selectedApplicationAccess, setSelectedApplicationAccess] = useState(user && [getApplicationAccessByElId(user.userType)]);
    const [selectedGroup, setGroup] = useState(user && [getGroupByElId(user.userType)]);
    const [isProfilesLoading, setIsProfilesLoading] = useState(false);
    const [isUserDetailsDisabled, setIsUserDetailsDisabled] = useState(true);
    const [isUsersLoading, setIsUsersLoading] = useState(false);
    const [userDetails, setUserDetails] = useState([]);
    const [personalizeEmail, setPersonalizeEmail] = useState([]);
    const [isError, setIsError] = useState('');
    let _typingTimeout = null;

    const getProfiles = async (text) => {
        const data = {
            text: text,
            filter: 2
        };

        if(_typingTimeout){
            clearTimeout(_typingTimeout);
        }

        _typingTimeout = setTimeout(async () => {
            if (text && text.length > 2) {
                await searchStore.profilesUsersSearch(data);
                const profiles = await searchStore.profilesUsersSearchResults.map(item => {
                    return {elId: item._id, value: `${item.firstName} ${item.lastName} ${item.email || item.company ? '-' : ''} ${item.email ? item.email : ''} ${item.company ? '@' : ''} ${item.company ? item.company : ''}`, profileData: item}
                });
                const profilesWithCreate = [...profiles, ...initProfiles()];
                setProfiles(profilesWithCreate);
            } else if (text === '') {
                searchStore.profilesUsersSearchResults = [];
            }
        }, 300);        
    }

    const removeMultiItem = (item, type) => {
    }
    const selectMultiItem = (item, type) => {
        switch(type) {
            case 'userDetails':
                setSelectedUserDetails([item]);
                break;
            case 'profile':
                handleSelectedProfile(item);
                break;
            case 'company':
                setSelectedCompany([item]);
                setSelectedUserDetails([]);
                setIsUserDetailsDisabled(false);
                handleUserDetailsByCompany(item);

                if (!utilsService.isEthosiaCompany(item._id)) {
                    setSelectedApplicationAccess(data.applicationAccess.filter(item => item.elId === 2));
                }
                break;
            case 'permissionType':
                setSelectedPermissionType([item]);
                break;
            case 'applicationAccess':
                setSelectedApplicationAccess([item]);
                break;
            case 'group':
                setSelectedApplicationAccess([item]);
                break;
            default:
                break;
        }
    }
    const handleSelectedProfile = (item) => {
        setSelectedProfile(item ? [item] : []);
        setProfiles(initProfiles());
        const firstName = item?.profileData?.firstName;
        const lastName = item?.profileData?.lastName;
        let email = '';
        if (item?.profileData) {
            email = utilsService.getPrivateEmail(item?.profileData);
        }
        setSelectedFirstName(firstName ? firstName : '');
        setSelectedLastName(lastName ? lastName : '');
        setSelectedEmail(email);
    }
    const handleUserDetailsByCompany = async (company) => {
        setIsUsersLoading(true);
        const users = await userStore.getNonUsersByCompany(company);
        const usersData = users.data && users.data.map(userData => {
            const mainEmail = userData.emails.find(email => email.isPrimary);
            if(!mainEmail) {
                return null;
            }
            return {user: userData, elId: userData._id, value: `${userData.firstName} ${userData.lastName} - ${mainEmail ? mainEmail.email : ''}`}
        });
        const filteredUsersData = usersData.filter(ud => ud);
        setUserDetails(filteredUsersData);
        setIsUsersLoading(false);
    }

    useEffect(() => {
        setIsError('');
        if(!props.isPrefilled) {
            if(props.screenType === 'edit' && user) {
                setSelectedEmail(`${user?.email}`);
            }
            else if(props.screenType === 'create' && props.company) {
                setSelectedCompany([{_id: props.company?._id, value: props.company?.name}]);
            }
        } else {
            setSelectedProfile([{elId: profile?._id, value: `${profile?.firstName || ''} ${profile?.lastName || ''} ${profile?.email || profile?.company ? '-' : ''} ${profile?.email ? profile?.email : ''} ${profile?.company ? '@' : ''} ${profile?.company ? profile?.company : ''}`}]);
            setSelectedFirstName(`${profile?.firstName || ''}`);
            setSelectedLastName(`${profile?.lastName || ''}`);
            // const primaryBusinessEmail = profile?.emails?.find(email => {
            //     if(email.emailType === 'Business Email') {
            //         return true;
            //     }

            //     return false;
            // });
            // if(!primaryBusinessEmail && user ) {
            //     setSelectedEmail(`${user.email}`);
            // } 
            // else {
            //     setSelectedEmail(`${primaryBusinessEmail ? primaryBusinessEmail.email : ''}`);
            // }
            if(user && user.email) {
                setSelectedEmail(`${user.email}`);
            } 
            else {
                for(let i = 0; profile.emails && i < profile.emails.length; ++i) {
                    const email = profile.emails[i].email;
                    const emailsArr = email.split('@');
                    if(emailsArr.length < 2)
                        return false;
        
                    const profileDomain = emailsArr[1];
                    if(props.company.companyDomain && props.company.companyDomain.includes(profileDomain)) {
                        // return email;
                        setSelectedEmail(email);
                    }
                }
            }

            if(props.company) {
                setSelectedCompany([{_id: props.company?._id, value: props.company?.name}]);
            }

            setSelectedApplicationAccess(user && [getApplicationAccessByElId(user.userType)])
        }
    }, []);
    const handleSubmitUser = () => {
        const errorMessage = validateErrors();
        if(errorMessage) {
            setIsError(`The following fields are missing: ${errorMessage}`);
            return;
        }
        else {
            setIsError('');
        }

        const isCreateNewProfile = selectedProfile[0]._id === "-1";
        if(!isCreateNewProfile) {
            const profile = selectedProfile[0];
            const company = selectedCompany[0];
            const applicationAccess = selectedApplicationAccess[0];
            const permissionType = selectedPermissionType[0];
            const data = {
                isCreateNewProfile: false,
                profileId: profile.elId,
                companyId: company._id,
                email: selectedEmail,
                applicationAccess: applicationAccess.elId,
                permissionType: permissionType.elId                
            }

            //if new profile
            if(profile.elId === "1") {
                data.firstName = selectedFirstName;
                data.lastName = selectedLastName;
            }

            if(props.screenType === "edit") {
                data.oldEmail = props.user && props.user.email
            }

            props.onClick(data);
        }
    }    

    const validateErrors = () => {
        let errorMessage = '';
        const hasNoProfile =  !selectedProfile || selectedProfile.length === 0;
        const hasNoCompany = !selectedCompany || selectedCompany.length === 0;
        const hasNoApplicationAccess = !selectedApplicationAccess || selectedApplicationAccess.length === 0;
        const hasNoPermissionType = !selectedPermissionType || selectedPermissionType.length === 0;
        if(hasNoProfile || !selectedFirstName || !selectedLastName || hasNoCompany || !selectedEmail ||
             hasNoApplicationAccess || hasNoPermissionType) {              
                if(!selectedProfile || selectedProfile.length === 0) {
                    errorMessage = 'Profile';
                }
                if(!selectedFirstName) {
                    errorMessage += errorMessage ? ' ,First Name' : 'First Name';
                }
                if(!selectedLastName) {
                    errorMessage += errorMessage ? ' ,Last Name' : 'Last Name';
                }
                if(!selectedEmail) {
                    errorMessage += errorMessage ? ' ,Email' : 'Email';
                }
                if(!selectedCompany || selectedCompany.length === 0) {
                    errorMessage += errorMessage ? ' ,Company' : 'Company';
                }
                // if(!selectedEmail) {
                //     errorMessage += errorMessage ? ' ,Email' : 'Email';
                // }
                if(!selectedApplicationAccess || selectedApplicationAccess.length === 0) {
                    errorMessage += errorMessage ? ' ,Application Access' : 'Application Access';
                }
                if(!selectedPermissionType || selectedPermissionType.length === 0) {
                    errorMessage += errorMessage ? ' ,Permission Type' : 'Permission Type';
                }
        }

        return errorMessage;
    }

    const handleOnChange = (type, e) => {
        const value = e.target.value;
        if(type === 'selectedFirstNames') {
            setSelectedFirstName(value);
        } else if(type === 'selectedLastName') {
            setSelectedLastName(value);
        } else if(type === 'email') {
            setSelectedEmail(value);
        }
    }
    const onEditorStateChange = text => {
        setPersonalizeEmail(text);
    };

    const inviteButtonClasses = () => {
        let classes = "button oval create-button " + (props.isLoading ? 'loading ' : '')
        return classes;
    }

    const getTitle = () => {
        switch(props.screenType) {
            case 'create':
                return 'Create User';
            case 'edit':
                return 'Edit User'
            case 'invite':
                return 'Invite User'
            default:
                return true;
        }
    }

    const getSubmitLabel = () => {
        switch(props.screenType) {
            case 'create':
            case 'edit':
                return 'Save'
            case 'invite':
                return 'Invite'
            default:
                return true;
        }
    }

    const isNameDisabled = () => {
        switch(props.screenType) {
            case 'create':
                return selectedProfile && selectedProfile.length > 0 &&
                 selectedProfile[0].elId !== '1'
            case 'edit':
                return 'Save'
            case 'invite':
                return 'Invite'
            default:
                return true;
        }    
    }

    const isEthosiaCompany = () => {
        const isEthosiaCompany = selectedCompany && Array.isArray(selectedCompany) &&
        selectedCompany.length > 0 && utilsService.isEthosiaCompany(selectedCompany[0]._id);
        return isEthosiaCompany;
    }

    const debounceSearchProfiles = useCallback(debounce(async (emailValue) => {
        const users = await userStore.getUsersByEmails([emailValue]);

        if (users[0]) {
            handleSelectedProfile({
                elId: users[0]._id,
                value: utilsService.getDisplayName(users[0]),
                profileData: users[0].profile ?? users[0],
            });
        } else {
            handleSelectedProfile(null);
        }
    }, 500), []);

    const handleBusinessEmailChange = (e) => {
        setSelectedBusinessEmail(e.target.value);
        debounceSearchProfiles(e.target.value);
    }

    const applicationAccess = isEthosiaCompany() ? data.applicationAccess : data.applicationAccess.filter(item => item.elId === 2);
    return (
        <ModalComponent>
            <div className="modal-box create-user">
                <div className="header">
                    <div className="header">{getTitle()}</div>
                    <div onClick={props.onClose}><CloseIcon2/></div>
                </div>

                <ComplexMultiSelectComponent2
                    // isDisabled={props.isPrefilled}
                    isDisabled={props.user}
                    isLoading={isProfilesLoading}
                    items={profiles}
                    selectedItems={selectedProfile ? selectedProfile : []}
                    placeholder={"User Profile"}
                    isSingle={true}
                    hasSuggested={false}
                    openOnKeyDown={false}
                    query={getProfiles}
                    removeItem={item => removeMultiItem(item, 'profile')}
                    selectItem={item => selectMultiItem(item, 'profile')}>
                </ComplexMultiSelectComponent2>
                <div className="names-container">
                    <ComplexInputComponent
                        isDisabled={isNameDisabled()}
                        placeholder={"First Name"}
                        value={selectedFirstName}
                        onChange={(e) => handleOnChange('selectedFirstNames', e)}>
                    </ComplexInputComponent>
                    <ComplexInputComponent
                        isDisabled={isNameDisabled()}
                        placeholder={"Last Name"}
                        value={selectedLastName}
                        onChange={(e) => handleOnChange('selectedLastName', e)}>
                    </ComplexInputComponent>
                </div>
                <BaseSearchWithNetwork 
                    networkRequest={companyStore.searchCompaniesByPartialNameByText}
                    numberOfCharsToPredicate={2}>
                    <ComplexMultiSelectComponent2
                        isDisabled={props.company}
                        selectedItems={selectedCompany ? selectedCompany : []}
                        placeholder={"Company"}
                        isSingle={true}
                        hasSuggested={false}
                        openOnKeyDown={false}
                        removeItem={item => removeMultiItem(item, 'company')}
                        selectItem={item => selectMultiItem(item, 'company')}
                    />
                </BaseSearchWithNetwork>
                <ComplexInputComponent
                    isDisabled={props.user && props.screenType === 'invite'}
                    placeholder={"Email"}
                    value={selectedEmail}
                    onChange={(e) => handleOnChange('email', e)}>
                </ComplexInputComponent>

                {props.screenType === 'create' && <ComplexInputComponent
                    placeholder='Business Email'
                    value={selectedBusinessEmail}
                    onChange={handleBusinessEmailChange}>
                </ComplexInputComponent>}

                <ComplexMultiSelectComponent2
                    items={applicationAccess}
                    selectedItems={selectedApplicationAccess ? selectedApplicationAccess : []}
                    placeholder={'Application Access'}
                    isSingle={true}
                    hasSuggested={false}
                    openOnKeyDown={false}
                    removeItem={item => removeMultiItem(item, 'applicationAccess')}
                    selectItem={item => selectMultiItem(item, 'applicationAccess')}>
                </ComplexMultiSelectComponent2>
                <ComplexMultiSelectComponent2
                    items={data.employers_permissions}
                    selectedItems={selectedPermissionType ? selectedPermissionType : []}
                    placeholder={'Permission'}
                    isSingle={true}
                    hasSuggested={false}
                    openOnKeyDown={false}
                    removeItem={item => removeMultiItem(item, 'permissionType')}
                    selectItem={item => selectMultiItem(item, 'permissionType')}>
                </ComplexMultiSelectComponent2>
                <div className="error-message">
                    <div>{props.isError ? props.isError : isError}</div>
                </div>
                <button 
                    className={inviteButtonClasses()}
                    onClick={handleSubmitUser}
                    onClose={props.onClose}>{getSubmitLabel()}</button>
            </div>
        </ModalComponent>
    );
};

export default CreateEditUser;
